import { useCryptr } from '@cryptr/cryptr-react';
import React from 'react'

const MyInfos = () => {
  const { user } = useCryptr();
  const currentUser = user()

  return (
    <div class="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
      <h2 class="mt-1 text-3xl lg:text-left tracking-tight text-gray-900 capitalize font-black sm:leading-snug sm:text-5xl lg:text-3xl xl:text-4xl">
        Mes informations
      </h2>
      <div>
        <div class="sm:hidden">
          <select
            aria-label="Selected tab"
            class="mt-1 form-select block w-full md:pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150"
          >
            <option>Mes données personnelles</option>
            <option selected>Formation en cours</option>
            <option>Formations terminées</option>
          </select>
        </div>
        <div class="hidden sm:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex">
              <a
                href="/"
                class="whitespace-no-wrap md:w-1/3 lg:w-auto ml-8 mr-8 py-4 px-1 border-b-2 border-yellow-500 font-medium text-sm leading-5 text-gray-700 focus:outline-none focus:text-gray-900 focus:border-yellow-600"
                aria-current="page"
              >
                Mes données personnelles
              </a>
              <a
                href="/"
                class="whitespace-no-wrap md:w-1/3 lg:w-auto py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-yellow-300"
              >
                Formation en cours
              </a>
              <a
                href="/"
                class="whitespace-no-wrap md:w-1/3 lg:w-auto ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
              >
                Formations terminées
              </a>
            </nav>
          </div>
        </div>
      </div>
      <div class="relative py-12">
        <p class="mt-4 text-center lg:text-left text-xl leading-7 text-gray-500">
          {currentUser ? (
            <div class="mt-3 mb-4">
              <p>
                <span>Vous avez eu accès à </span>
                <pre class="border border-teal-200 bg-teal-100 text-gray-500 w-auto px-2 py-1 my-2 inline-block mx-2">
                  <code>{currentUser.aud}</code>
                </pre>
              </p>
              <p>
                <span>de l'emmetteur</span>
                <pre class="border border-teal-200 bg-teal-100 text-gray-500 w-auto px-2 py-1 my-2 inline-block mx-2">
                  <code>
                    {currentUser.iss} ("{currentUser.tnt}")
                  </code>
                </pre>
              </p>
              <p>
                <span>avec les droits suivants</span>
                <ul>
                  {currentUser.scp.map((scope) => (
                    <li class="text-teal-500 border-2 border-gray-200 w-auto inline-block px-2 m-1">
                      {scope}
                    </li>
                  ))}
                </ul>
              </p>
            </div>
          ) : (
            <div
              class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md"
              role="alert"
            >
              <div class="flex">
                <div class="py-1">
                  <svg
                    class="fill-current h-6 w-6 text-red-500 mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div>
                  <p class="font-bold">
                    Impossible d'afficher vos informations
                  </p>
                  <p class="text-sm">Votre identité n'est pas présente</p>
                </div>
              </div>
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default MyInfos