import { AccountButton, useCryptr } from '@cryptr/cryptr-react';
import React from 'react'
import { Link } from 'react-router-dom'

const Nav = () => {
  const { user } = useCryptr()
  
  return (
    <nav class="bg-white">
      <div class="mx-auto max-w-screen-xl pt-3 md:pt-4 lg:pt-5 px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="-ml-2 mr-2 flex items-center md:hidden">
              {/* Mobile menu button */}
              <button
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                aria-label="Main menu"
                aria-expanded="false"
              >
                {/* Icon when menu is closed. */}
                <svg
                  class="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* Icon when menu is open. */}
                <svg
                  class="hidden h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <Link class="flex-shrink-0 flex items-center" to="/">
              <img
                class="h-12 w-auto"
                src="https://images.prismic.io/shark-academy%2F76b9fccf-b146-4c3d-a068-a96c07d61085_logo_shark_academy__no_spacing.svg?auto=compress,format"
                alt="Shark academy logo"
              />
              <div class="hidden lg:block">
                <div class="flex flex-wrap uppercase w-40 ml-3 text-base">
                  <span class="text-gray-900 font-bold">Shark academy</span>
                  <span class="text-gray-600">Data bootcamp</span>
                </div>
              </div>
            </Link>
            <div class="hidden md:ml-6 md:flex md:items-center">
              {/* <Link to="/" class="px-3 py-2 rounded-md text-sm font-medium leading-5 text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out">Home</Link> */}
              <Link
                to="/react"
                class="ml-4 px-3 py-2 rounded-md text-base font-medium leading-5 text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-700 transition duration-150 ease-in-out"
              >
                React
              </Link>
              <Link
                to="/graphql"
                class="ml-4 px-3 py-2 rounded-md text-base font-medium leading-5 text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-700 transition duration-150 ease-in-out"
              >
                GraphQL
              </Link>
              <Link
                to="/growth-hacker"
                class="ml-4 px-3 py-2 rounded-md text-base font-medium leading-5 text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-700 transition duration-150 ease-in-out"
              >
                Growth hacker
              </Link>
            </div>
          </div>

          <div class="flex items-center">
            <div class="flex-shrink-0">
              <span class="rounded-md">
                <AccountButton 
                  tenantName="Shark Academy"
                  tenantLogo="https://images.prismic.io/shark-academy%2F76b9fccf-b146-4c3d-a068-a96c07d61085_logo_shark_academy__no_spacing.svg?auto=compress,format"
                  defaultSignText="Connexion"
                  className="relative inline-flex items-center px-5 py-3 border border-transparent text-base uppercase leading-5 font-bold rounded-md text-yellow-500 shadow-md hover:shadow-xl focus:outline-none transition duration-150 ease-in-out"
                >
                  <div className='flex flex-col space-y-2 divide-y'>
                    <Link 
                      to="/mes-formations"
                      className="space-y-1"
                    >
                      <p className="text-base leading-6 font-medium text-gray-900">
                        My courses
                      </p>
                      <p className="text-sm leading-5 text-gray-500">
                        Click to continue learning and see your in-progress courses
                      </p>
                    </Link>
                    {user() && user() !== undefined && 
                      <div className='space-y-1 mt-2'>
                        <ul className='space-y-1 mt-2'>
                          <li>tnt: {user().tnt}</li>
                          <li>ips: {user().ips}</li>
                          <li>
                            sci: 
                            <small>{user().sci}</small>
                          </li>
                        </ul>
                      </div>
                    }
                  </div>
                </AccountButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav