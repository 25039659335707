import React from 'react';
import {SsoGatewayButton, useCryptr} from '@cryptr/cryptr-react'

const GatewayDropdown = () => {
  const {isAuthenticated, isLoading} = useCryptr();
  const idpIds = process.env.REACT_APP_CRYPTR_IDP_IDS ? process.env.REACT_APP_CRYPTR_IDP_IDS.split(",") : []

  if ((isAuthenticated !== undefined && isAuthenticated()) || isLoading) {
    return <></>
  }

  return (
    <div className="relative inline-block text-left">
      <p className='mb-8'>
      Connect through SSO
      </p>

       <span className="relative z-0 inline-flex shadow-sm rounded-md">
        <SsoGatewayButton 
          className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-yellow-300 bg-white text-sm font-medium text-yellow-700 hover:bg-yellow-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          text="Bare Gateway"
          />
        {idpIds.length > 0 && (
          <>
            <SsoGatewayButton 
              className="-ml-px relative inline-flex items-center px-4 py-2 border border-yellow-300 bg-white text-sm font-medium text-yellow-700 hover:bg-yellow-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              text="One SSO Gateway"
              idpIds={idpIds[0]}
              />
            <SsoGatewayButton 
              className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-yellow-300 bg-white text-sm font-medium text-yellow-700 hover:bg-yellow-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              text="Multiple SSO Gateway"
              idpIds={idpIds}
              />
          </>
        )}

       </span>
      </div>
  )

}

export default GatewayDropdown