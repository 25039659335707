import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'

import Nav from './components/Nav'
import Footer from './components/Footer'
import history from './utils/history'
import ReactPage from './pages/react'
import GraphqlPage from './pages/graphql'
import GrowthHackerPage from './pages/growth-hacker'
import MyCourses from './pages/mes-formations'
import MyInfos from './pages/mes-informations'
import Home from './pages/home'
import { CryptrProvider } from '@cryptr/cryptr-react'

const config = {
  audience: process.env.REACT_APP_CRYPTR_AUDIENCE,
  client_id: process.env.REACT_APP_CRYPTR_CLIENT_ID,
  cryptr_base_url: process.env.REACT_APP_CRYPTR_BASE_URL,
  dedicated_server: process.env.REACT_APP_CRYPTR_DEDICATED_SERVER === 'true' || false,
  default_scopes: process.env.REACT_APP_CRYPTR_DEFAULT_SCOPES || 'openid email profile',
  default_locale: process.env.REACT_APP_CRYPTR_DEFAULT_LOCALE || 'en',
  default_redirect_uri: process.env.REACT_APP_CRYPTR_DEFAULT_REDIRECT_URI,
  telemetry: process.env.REACT_APP_CRYPTR_TELEMETRY === 'true' || false,
  tenant_domain: process.env.REACT_APP_CRYPTR_TENANT_DOMAIN,
}

const App = () => {
  return (
    <CryptrProvider {...config}>
        <div className="App">
          {/* Don't forget to include the history module */}
          <Router history={history}>
            <Nav />
            <Switch>
              <Route path="/" exact render={() => <Home />}/>
              <Route path="/react" render={() => <ReactPage />}/>
              <Route path="/graphql" render={() => <GraphqlPage />}/>
              <Route path="/growth-hacker" render={() => <GrowthHackerPage />}/>
              <Route path="/mes-formations" render={() => <MyCourses />}/>
              <Route path="/mes-informations" render={() => <MyInfos />}/>
            </Switch>
            <Footer />
          </Router>
      </div>
    
    </CryptrProvider>
  )
}

export default App
