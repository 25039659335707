import React, {useEffect, useState} from 'react'
import { useCryptr } from '@cryptr/cryptr-react'

const MyCourses = () => {
  const [courses, setCourses] = useState([])
  const [tab, setTab] = useState(1)
  const {decoratedRequest} = useCryptr()
  const resourceUrl = process.env.RESOURCE_URL || "http://localhost:8081" 
  const coursesUrl = `${resourceUrl}/api/v1/courses`

  useEffect(() => {
    decoratedRequest({
      method: 'GET',
      url: coursesUrl
    }).then((data) => {
      console.log(data.data)
      setCourses(data.data)
    }).catch(error => {
      console.error(error)
    })
  }, [decoratedRequest, coursesUrl])

  const tabClass = (tabIndex) => {
    if(tab === tabIndex) {
      return "whitespace-no-wrap md:w-1/3 lg:w-auto mx-4 py-4 px-1 border-b-2 border-yellow-500 font-medium text-sm leading-5 text-gray-700 focus:outline-none focus:text-gray-900 focus:border-yellow-600"
    }
    return "whitespace-no-wrap md:w-1/3 lg:w-auto mx-4 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
  }

  const currentCourses = () => {
    if(tab === 0) {
      return courses
    }

    let now = new Date()
    return courses.filter(course => {
      let courseDate = new Date(course.timestamp)
      return tab === 1 ? now < courseDate : now > courseDate
    })
  }

  const courseLabelStyle = (course) => {
    return isOldCourse(course) ? "line-through" : ""
  }

  const isOldCourse = (course) => {
    let courseDate = new Date(course.timestamp)
    let now = new Date()
    return courseDate < now
  }

  const coursePointer = (course) => {
    return isOldCourse(course) ? 'cursor-not-allowed' : 'cursor-pointer'
  }


  
  return (
    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
      <h2 className="mt-1 text-3xl lg:text-left tracking-tight text-gray-900 capitalize font-black sm:leading-snug sm:text-5xl lg:text-3xl xl:text-4xl">
        Mes formations
      </h2>
      <div>
        <div className="sm:hidden">
          <select aria-label="Selected tab" className="mt-1 form-select block w-full md:pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150">
            <option>Toutes mes formations
            </option>
            <option selected>Formation en cours
            </option>
            <option>Formations terminées
            </option>
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex">
              <button 
                onClick={()=> setTab(0)} 
                className={tabClass(0)}
              >
                Toutes mes formations
              </button>
              <button 
                onClick={()=> setTab(1)}
                className={tabClass(1)}
              >
                Formation en cours
              </button>
              <button 
                onClick={()=> setTab(2)} 
                className={tabClass(2)}
              >
                Formations terminées
              </button>
            </nav>
          </div>
        </div>
      </div>
      <div className="relative py-12">
        {(courses === undefined || courses.length < 1) ? (
          <p className="mt-4 text-center lg:text-left text-xl leading-7 text-gray-500">
            Pas de formation en cours
          </p>
        ) : (
          currentCourses().map((course) => {

            return (
              <div
                className="flex flex-wrap mb-6 max-w-sm w-full md:max-w-full shadow-lg border rounded-lg overflow-hidden">
                <div className={`flex flex-col md:flex-row w-full h-full ${coursePointer(course)}`}>
                  <div className="flex items-center w-full h-48 md:h-auto md:w-64">
                    <img className="w-auto h-auto object-top md:object-center object-cover" src={course.img} alt={course.title}/>
                  </div>
                  <div
                    className="flex w-full h-full border-gray-400 bg-gray-100 lg:rounded-r-lg p-6 flex flex-col justify-between leading-normal">
                    <div className="mb-8">
                      <p className="text-sm text-blue-500 flex items-center">
                        <svg className="fill-current text-blue-500 w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path
                            d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                        </svg>
                        Members only
                      </p>
                      <div className={`text-gray-900 font-bold text-xl mt-2 ${courseLabelStyle(course)}`}>
                        {course.title}
                      </div>
                      <p className={`text-gray-600 text-base md:h-20 lg:h-12 my-3 ${courseLabelStyle(course)}`}>
                        {course.desc}
                      </p>
                      <div className="pt-3 mt-6">
                        {course.tags.map((tag) => {
                          return (
                            <span
                              className="inline-block bg-yellow-500 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                #{tag}
                            </span>
                          )
                        })}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <img className="w-10 h-10 rounded-full mr-4 object-cover" src={course.teacher.picture} alt={course.teacher.name} />
                      <div className="text-sm">
                        <p className="text-gray-900 leading-none">{course.teacher.name}</p>
                        <p className="text-gray-600">{course.date}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default MyCourses