import React from 'react'
import { SignUpButton } from '@cryptr/cryptr-react'

const ReactPage = () => {
  return (
    <main class="mt-8 pb-12 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
      <div class="lg:grid lg:grid-cols-12 lg:gap-8">
        <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <div class="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
            Bientôt disponible
          </div>
          <h2 class="mt-1 text-3xl tracking-tight text-gray-900 font-normal sm:leading-snug sm:text-5xl lg:text-3xl xl:text-4xl">
            Formation
            <br class="hidden md:inline" />
            <span class="capitalize font-black">React
            </span>
          </h2>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
            Faites passer votre carrière de développeur au niveau supérieur avec notre formation React.
          </p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <SignUpButton 
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-sm leading-6 font-bold shadow-md rounded-md uppercase text-gray-900 bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow transition duration-150 ease-in-out md:py-4 md:text-base md:px-10"
                text="M'inscrire"/>
            </div>
          </div>
        </div>
        <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div class="relative mx-auto w-full lg:max-w-md">
            <div type="button" class="relative block w-full overflow-hidden">
              <img class="w-full" src="https://images.prismic.io/shark-academy/d3215a0c-038c-424d-9dac-7535b4cdfb4b_react_illustration_header.svg?auto=compress,format" alt="Shark academy react" />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ReactPage